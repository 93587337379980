/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as authHelper from '@utils/AuthHelpers';
import { WithChildren } from '@type/consts';
import { useRouter } from 'next/router';
import { getCookie, hasCookie, deleteCookie } from 'cookies-next';
import { firebaseAuth } from '@utils/firebase';
import { StorageKeys } from '@data/constant';
import { onAuthStateChanged } from 'firebase/auth';

export type AuthContextProps = {
  auth: string | undefined;
  saveAuth: (
    accessToken: string | undefined,
    refreshToken: string | undefined
  ) => void;
  logout: () => void;
  isAuthenticated: boolean;
  user?: string;
  userToken?: string;
  saveDataUser: any;
  saveUser: any;
  setImgProfile: any;
  imgProfile?: string;
};

const initAuthContextPropsState = {
  auth: undefined,
  saveAuth: () => {},
  logout: () => {},
  isAuthenticated: false,
  saveDataUser: {},
  saveUser: {},
  setImgProfile: undefined,
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuthContext = () => useContext(AuthContext);

const AuthContextProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<string | undefined>(authHelper.getAuth());
  const [user, setUser] = useState<any>(firebaseAuth.currentUser);
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [userToken, setUserToken] = useState<string | undefined>(undefined);
  const [saveUser, setSaveUser] = useState<any>(undefined);
  const [imgProfile, setImgProfile] = useState<string | undefined>(undefined);

  const saveAuth = (
    accessToken: string | undefined,
    refreshToken: string | undefined
  ) => {
    setAuth(accessToken);
    if (accessToken) {
      setUserToken(accessToken);
    }
    if (accessToken && refreshToken) {
      authHelper.setAuth(accessToken, refreshToken);
    } else {
      firebaseAuth.signOut();
      // Log out from Firebase
      authHelper.removeAuth();
    }
  };

  const saveDataUser = (data: any) => {
    setSaveUser(data);
  };

  const logout = async () => {
    authHelper.removeAuth();
    localStorage.clear();
    await firebaseAuth.signOut();
    saveAuth(undefined, undefined);
    saveDataUser(undefined);
    setImgProfile(undefined);
    deleteCookie(String(StorageKeys.AccessToken));
    deleteCookie(String(StorageKeys.RefreshToken));
    deleteCookie(String(StorageKeys.ExpAccessToken));
    deleteCookie(String(StorageKeys.ExpRefreshToken));
    deleteCookie(String(StorageKeys.User));
    deleteCookie(String(StorageKeys.UserImg));
    router.replace('/login');
  };

  const isAuthenticated = !!auth;

  useEffect(() => {
    // Check for existing cookies on mount
    const accessToken = getCookie(String(StorageKeys.AccessToken));
    const refreshToken = getCookie(String(StorageKeys.RefreshToken));
    // Listen for changes in authentication state
    

    const unsubscribe = onAuthStateChanged(firebaseAuth, (user: any) => {
      if (user !== null) {
        saveAuth(user?.accessToken, user.refreshToken);
        authHelper.setAuth(user?.accessToken, user.refreshToken);
        setUser(user);
      }else{
        setLoading(false);
      }
    });

    if (hasCookie(String(StorageKeys.AccessToken)) && hasCookie(String(StorageKeys.RefreshToken)) && hasCookie(String(StorageKeys.User))) {
      saveAuth(accessToken as string, refreshToken as string);
      const userDB = JSON.stringify(getCookie(StorageKeys.User) as string);
      const userParse = JSON.parse(userDB);
      setSaveUser(userParse);
      saveDataUser(userParse);
      const userImg = JSON.stringify(getCookie(StorageKeys.UserImg) as string);
      const imgParse = JSON.parse(userImg);
      setImgProfile(imgParse);
    } else {
      setLoading(false);
    }

    return () => {
      // Unsubscribe when the component unmounts
      unsubscribe();
    };
  }, []);

  const valueAuthContext = useMemo(
    () => ({
      auth,
      user: user?.uid, // Use the user's UID as an example
      userToken,
      setUserToken,
      saveAuth,
      saveDataUser,
      saveUser,
      imgProfile,
      setImgProfile,
      logout,
      isAuthenticated,
    }),
    [auth, user, isAuthenticated, logout]
  );

  return (
    loading === false && (
      <AuthContext.Provider value={valueAuthContext}>
        {children}
      </AuthContext.Provider>
    )
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { isAuthenticated, logout } = useAuthContext();

  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
  }, [isAuthenticated, logout]);

  return <>{children}</>;
};

export { AuthContextProvider, useAuthContext, AuthInit };
