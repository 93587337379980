import {
  getCookie,
  setCookie,
  deleteCookie,
  hasCookie,
  CookieValueTypes,
} from 'cookies-next';
import { StorageKeys } from '@data/constant';
import { isEmptyString } from '@utils/stringUtils';
import dayjs from 'dayjs';
import _ from 'lodash';
import { jwtDecode } from 'jwt-decode';

const getAuth = (): string | undefined => {
  if (!hasCookie(String(StorageKeys.AccessToken))) {
    return;
  }

  const auth: CookieValueTypes = getCookie(String(StorageKeys.AccessToken));
  if (typeof auth !== 'string' || isEmptyString(auth)) {
    return;
  }

  try {
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error('TOKEN LOCAL COOKIES PARSE ERROR', error);
  }
};

const getRefreshToken = (): string | undefined => {
  if (!hasCookie(String(StorageKeys.RefreshToken))) {
    return;
  }

  const refreshToken: CookieValueTypes = getCookie(String(StorageKeys.RefreshToken));
  if (typeof refreshToken !== 'string' || isEmptyString(refreshToken)) {
    return;
  }

  try {
    if (refreshToken) {
      // You can easily check auth_token expiration also
      return refreshToken;
    }
  } catch (error) {
    console.error('REFRESH TOKEN LOCAL COOKIES PARSE ERROR', error);
  }
};

const setAuth = (accessToken: string, refreshToken: string) => {
  try {
    const refresh = jwtDecode(accessToken);
    const exp_refreshToken = dayjs(_.get(refresh, 'exp'))
      .unix();
    setCookie(String(StorageKeys.ExpAccessToken), exp_refreshToken);
    setCookie(String(StorageKeys.ExpRefreshToken), exp_refreshToken);
    setCookie(String(StorageKeys.AccessToken), accessToken);
    setCookie(String(StorageKeys.RefreshToken), refreshToken);
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error);
  }
};

const removeAuth = () => {
  if (
    !hasCookie(String(StorageKeys.AccessToken)) ||
    !hasCookie(String(StorageKeys.RefreshToken))
  ) {
    return;
  }

  try {
    deleteCookie(String(StorageKeys.ExpAccessToken));
    deleteCookie(String(StorageKeys.ExpRefreshToken));
    deleteCookie(String(StorageKeys.AccessToken));
    deleteCookie(String(StorageKeys.RefreshToken));
    deleteCookie(String(StorageKeys.User));
    deleteCookie(String(StorageKeys.UserImg));
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error);
  }
};

export { getAuth, setAuth, removeAuth, getRefreshToken };
