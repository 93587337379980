/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import '@styles/globals.css';
import React, { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import isPropValid from '@emotion/is-prop-valid';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { AuthContextProvider } from '@context/AuthContext';
import { StyleSheetManager } from 'styled-components';

dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Jakarta');

const App = ({ Component, pageProps }: AppProps<{ isSsrMobile: boolean }>) => {
  const isLocalStorageAvailable =
    typeof window !== 'undefined' && window.localStorage;
  const [permission, setPermission] = useState<any>(null);
  useEffect(() => {
    if (isLocalStorageAvailable) {
      setPermission(true);
    }
  }, [isLocalStorageAvailable]);
  return (
    permission !== null && (
      <StyleSheetManager
        enableVendorPrefixes
        shouldForwardProp={(propName, elementToBeRendered) => {
          return typeof elementToBeRendered === 'string'
            ? isPropValid(propName)
            : true;
        }}
      >
        <AuthContextProvider>
          <Component {...pageProps} />
        </AuthContextProvider>
      </StyleSheetManager>
    )
  );
};
export default App;
